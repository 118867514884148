import Layout from "../../../components/Layout";
import { useRouter } from "next/router";
import classNames from "classnames";
import Link from "next/link";
import moment from "moment";
import { useState, useEffect, useRef } from "react";
import useSWR, { mutate } from "swr";
import Head from "next/head";
import copy from "copy-to-clipboard";
import axios from "axios";

import api, { fetchTenantConfig } from "../../../services/api";

import FilmDetailTable from "../../../components/FilmDetailTable";
import DropdownButton from "../../../components/DropdownButton";
import {
  formatPrice,
  supportsCustomScrollbars,
  formatBrowserTimezone
} from "../../../util";
import CheckoutModal from "../../../components/CheckoutModal";
import LiveBadge from "../../../components/LiveBadge";
import Button from "../../../components/Button";
import NoContentPlaceholder from "../../../components/NoContentPlaceholder";
import BallotingWidget from "../../../components/BallotingWidget";
import Modal from "../../../components/Modal";
import ConfirmWatchNowModal from "../../../components/ConfirmWatchNowModal";
import SocialShare from "../../../components/SocialShare";

import { useAuth, singleOrganization } from "../../../hooks/useAuth";
import { useStickyResult, useSecurityCheck } from "../../../hooks";

import dynamic from "next/dynamic";
import Countdown from "../../../components/Countdown";
import AvailabilityInformation from "../../../components/AvailabilityInformation";
import toast from "react-hot-toast";

const LivestreamModal = dynamic(
  () => import("../../../components/LivestreamModal"),
  {
    ssr: false,
    loading: () => <Modal loader />
  }
);
const PlayerModal = dynamic(() => import("../../../components/PlayerModal"), {
  ssr: false,
  loading: () => <Modal loader />
});
const MultifactorInterstitialModal = dynamic(
  () => import("../../../components/MultifactorInterstitialModal"),
  {
    ssr: false,
    loading: () => <Modal loader />
  }
);

const SHOW_ANTIPIRACY_BADGE = false;

const EXTERNAL_APP_LINK_CONFIRMATION_MESSAGE = `Link copied successfully. Now paste it into your browser. Enjoy the film!`;

export default function Play({ initialData, initialKey, requestLoginModal }) {
  const router = useRouter();
  const [programId, contentId] = router.query.programId || [];

  const {
    apiLoaded,
    festivalId,
    tenant,
    token,
    user,
    colorPalette,
    adminPreviewSettings
  } = useAuth();

  const [invalidURL, setInvalidURL] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [hasRefreshed, setHasRefreshed] = useState(false);
  const [language, setLanguage] = useState("en");

  const getEventEndpoint = `watch/events/${programId}${
    token ? `?token=${token}` : ""
  }`;
  const {
    data: rawVirtualEvent,
    error,
    mutate: localMutate,
    isValidating
  } = useSWR(
    apiLoaded && festivalId === router.query.festivalId && getEventEndpoint,
    k => api.get(k).then(r => r.data),
    {
      initialData: !hasRefreshed ? initialData : undefined
    }
  );
  const virtualEvent = useStickyResult(rawVirtualEvent);

  // Fix for FrankenURLs = tenant event bucket must match the virtualEvent event bucket
  useEffect(() => {
    if (tenant && tenant.event_bucket && virtualEvent && virtualEvent.event_bucket_id) {
      if (tenant.event_bucket !== virtualEvent.event_bucket_id) {
        setInvalidURL(true);
      }
    }
  }, [tenant, virtualEvent]);

  const {
    isExtremeSecurityOkay,
    blockedExtremeSecurityBrowser,
    recommendedExtremeSecurityBrowser
  } = useSecurityCheck();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsRefreshing(true);
    }
  }, [token]);

  useEffect(() => {
    if (window) {
      const navigator = window.navigator;
      const userLang = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
      setLanguage(userLang);
    }
  }, [typeof window]);

  useEffect(() => {
    if (!isValidating) {
      setIsRefreshing(false);
      setHasRefreshed(true);
    }
  }, [isValidating]);

  useEffect(() => {
    if (initialData && initialKey !== getEventEndpoint) {
      setIsRefreshing(true);
      localMutate();
    }
  }, []);

  // Mobile App Identification
  const [isApp, setIsApp] = useState(false);
  const [isAppleApp, setIsAppleApp] = useState(false);
  const [isAndroidApp, setIsAndroidApp] = useState(false);
  const [isAppLockdownMode, setIsAppLockdownMode] = useState(false);
  const [allowInAppPurchases, setAllowInAppPurchases] = useState(false);
  const [allowInAppFreeUnlocks, setAllowInAppFreeUnlocks] = useState(false);

  useEffect(() => {
    const isApple =
      navigator.userAgent.toLowerCase().indexOf("gonative") > -1 &&
      /iPad|iPhone|iPod/.test(navigator.userAgent);

    const isAndroid =
      navigator.userAgent.toLowerCase().indexOf("gonativeandroid") > -1;

    const isMobileApp = isApple || isAndroid;
    setIsApp(isMobileApp);
    setIsAppleApp(isApple);
    setIsAndroidApp(isAndroid);
    setIsAppLockdownMode(isMobileApp && tenant?.apple_app_lockdown_mode);
    setAllowInAppPurchases(tenant?.allow_in_app_purchases || !isApple);
    setAllowInAppFreeUnlocks(tenant?.allow_in_app_free_unlocks || !isApple);
  }, []);

  const [isAarp, setIsAarp] = useState(false);
  const [aarpEventType, setAarpEventType] = useState("screening");

  useEffect(() => {
    if (tenant.use_aarp_registration_flow) {
      setIsAarp(true);
    }
    if (tenant.aarp_event_type) {
      setAarpEventType(tenant.aarp_event_type);
    }
  }, [tenant]);

  const [checkoutOptions, setCheckoutOptions] = useState(null);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);

  const [isWatchingNow, setIsWatchingNow] = useState(false);
  const [watchError, setWatchError] = useState(null);

  // Interstitial screen
  const [showConfirmStartWatching, setShowConfirmStartWatching] = useState(
    false
  );

  const [showMutifactorInterstitial, setShowMultifactorInterstitial] = useState(
    null
  );

  const [showPlayerForPayload, setShowPlayerForPayload] = useState(null);

  const [showLivestreamWithOptions, setShowLivestreamWithOptions] = useState(
    null
  );

  const [showExternalAppDestination, setShowExternalAppDestination] = useState(
    null
  );

  useEffect(() => {
    // Open checkout modal to complete order
    if (
      typeof window !== "undefined" &&
      window.location.href.indexOf("_complete_checkout") !== -1
    ) {
      setShowCheckoutModal(true);
    }
  }, [typeof window]);

  const t = function () {};
  useEffect(() => {
    // Force reload if we ever discover that a user no longer has a valid ticket
    if (
      showPlayerForPayload &&
      !showPlayerForPayload.isTrailer &&
      !virtualEvent?.person?.available_ticket &&
      !adminPreviewSettings
    ) {
      document.body.innerHTML = "";
      setTimeout(() => {
        window.alert(
          `Error during playback: Your login has expired. Please try again.`
        );
        window.location.reload();
      }, 100);
      return () => {};
    }

    // If viewing an event that begins within the next day, schedule a refresh to ensure
    // we pop up up the watch/unlock now the moment it goes live!
    if (virtualEvent?.start_time) {
      const MAX_DELTA = 1000 * 60 * 60 * 24;
      const timeToEvent =
        new Date(
          virtualEvent.start_time_enter_broadcast || virtualEvent.start_time
        ) - Date.now();
      if (timeToEvent > 0 && timeToEvent < MAX_DELTA) {
        const interval = setTimeout(() => {
          setIsRefreshing(true);
          setTimeout(() => setIsRefreshing(false), 1000);
        }, timeToEvent + 100);
        return () => clearTimeout(interval);
      }
    }
  }, [virtualEvent]);

  const [contentSidebarScrollHeight, setContentSidebarScrollHeight] = useState(
    0
  );
  const contentSidebar = useRef(null);
  const selectedContentSidebarItemRef = useRef(null);

  const selectedContentId =
    contentId || (virtualEvent?.films?.length && virtualEvent.films[0].id);
  const selectedContent = virtualEvent?.films?.find(
    c => c.id === selectedContentId
  );

  useEffect(() => {
    if (contentSidebar.current) {
      setContentSidebarScrollHeight(contentSidebar.current.scrollHeight);
    }
  }, [virtualEvent, selectedContentId]);

  useEffect(() => {
    if (selectedContentSidebarItemRef.current && contentSidebar.current) {
      let c = selectedContentSidebarItemRef.current;
      contentSidebar.current.scrollTo({
        top: c ? c.offsetTop - 24 : 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }, [
    selectedContentId,
    contentSidebar.current,
    selectedContentSidebarItemRef.current
  ]);

  const isUnlocked = !!virtualEvent?.person?.available_ticket;

  // If it is a combo event then allow livestream access even after main watch window has expired.
  const isLivestreamUnlocked =
    !!virtualEvent?.person?.available_ticket_livestream &&
    virtualEvent?.films?.length > 1;

  const passes = virtualEvent?.person?.passes
    ? virtualEvent.person.passes.reduce((o, p) => {
        o[p.id] = p;
        if (tenant?.sundance_login && p.pass_bucket?.name) {
          o[p.id].name = p.pass_bucket.name;
        }
        return o;
      }, {})
    : {};

  const ticketsOptions =
    virtualEvent?.ticket_buckets.reduce((options, bucket) => {
      let newOptions = [];
      newOptions.push({ ...bucket, currency: tenant.currency });
      if (bucket.variants && bucket.variants.length) {
        bucket.variants.forEach(({ id, name, price, currency }) => {
          newOptions.push({
            ...bucket,
            name: `${bucket.name} – ${name}`,
            price,
            variantId: id,
            currency: currency || bucket.currency || tenant.currency
          });
        });
      }
      return options.concat(newOptions);
    }, []) || [];

  let salesOptions = ticketsOptions
    .reduce((options, bucket) => {
      let newOptions = [];
      if (bucket.public && bucket.quantity_remaining) {
        if (!bucket.applicable_pass_buckets.length) {
          newOptions.push({
            ticket_bucket_id: bucket.id,
            ticket_bucket_name: bucket.name,
            price: bucket.price,
            variantId: bucket.variantId,
            currency: bucket.currency
          });
        }
        if (bucket.pass_adjusted) {
          Object.keys(bucket.pass_adjusted).forEach(passId => {
            const pass = passes[passId];
            if (pass?.pass_bucket?.hide_from_users) {
              // skip hidden passes
              return;
            }

            if (bucket.pass_adjusted[passId].quantity_available) {
              newOptions.push({
                ticket_bucket_id: bucket.id,
                ticket_bucket_name: bucket.name,
                pass_name: passes[passId].name,
                pass: passId,
                price: bucket.pass_adjusted[passId].price,
                variantId: bucket.variantId,
                currency: bucket.currency
              });
            } else {
              newOptions.push({
                ticket_bucket_id: bucket.id,
                ticket_bucket_name: bucket.name,
                pass_name: passes[passId].name,
                pass: passId,
                error_message:
                  bucket.pass_adjusted[passId].errors.join(" ") ||
                  "The pass does not apply to this item.",
                variantId: bucket.variantId,
                currency: bucket.currency
              });
            }
          });
        }
      }
      return options.concat(newOptions);
    }, [])
    .sort((a, b) => (!!a.pass === !!b.pass ? 0 : a.pass ? -1 : 1));

  // for the app, if free unlocks are allowed but purchases are not,
  // remove all tickets that have a price greater than 0
  if (
    salesOptions &&
    salesOptions.length &&
    isApp &&
    allowInAppFreeUnlocks &&
    !allowInAppPurchases
  ) {
    salesOptions = salesOptions.reduce((arr, option) => {
      if (option.price <= 0) {
        arr.push(option);
      }
      return arr;
    }, []);
  }

  // Add credits option
  if (virtualEvent?.person?.credits_available) {
    const matching = salesOptions.filter(
      o => o.ticket_bucket_id && o.price && !o.pass
    );
    salesOptions = matching
      .map(o => ({
        ticket_bucket_id: o.ticket_bucket_id,
        ticket_bucket_name: o.ticket_bucket_name,
        price: 0,
        with_credits: true,
        currency: o.currency
      }))
      .concat(salesOptions);
  }

  // Determine if multi-currency eligible, in which case filter to only show
  // the currently selected currency.
  const availableCurrencies = [
    ...new Set(salesOptions.map(o => o.currency).filter(x => !!x))
  ];
  const hasMultiCurrency = availableCurrencies.length > 1;
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  
  useEffect(() => {
    if (hasMultiCurrency) {
      axios
        .post("/api/geo_currency_lookup")
        .then(r => {
          const navigator = window.navigator;
          const browserLang = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'none';
          let desiredCurrency = r.data?.currency?.toLowerCase();
          if (
            desiredCurrency &&
            availableCurrencies.includes(desiredCurrency)
          ) {
            setDefaultCurrency(desiredCurrency);
          // TEMP HACK For German and Swiss Greman language to default to EUR currency
          } else if (browserLang.toLowerCase().startsWith("de-ch")) {
            setDefaultCurrency("chf")
          } else if (browserLang.toLowerCase().startsWith("de")) {
            setDefaultCurrency("eur");
          } else {
            setDefaultCurrency(availableCurrencies[0]);
          }
        })
        .catch(e => {
          setDefaultCurrency(availableCurrencies[0]);
        });
    }
  }, [hasMultiCurrency, typeof window]);

  if (
    (!virtualEvent && !error) ||
    (hasMultiCurrency && !defaultCurrency && typeof window !== "undefined")
  ) {
    return <Layout key="loader" loader={true} />;
  }

  if (!virtualEvent?.films?.length || error) {
    return (
      <Layout key="nocontent" subtitle={virtualEvent?.name}>
        <NoContentPlaceholder
          text={
            error
              ? `Unable to load item: ${
                  error.message.indexOf("Cast to ObjectId failed for value") !==
                  -1
                    ? `Invalid content identifier, please double-check the URL`
                    : error.message
                }`
              : `This item doesn't have any currently available virtual content`
          }
          emoji="🎞️"
        />
      </Layout>
    );
  }

  if (!selectedContent || invalidURL) {
    return (
      <Layout key="contentnotfound" subtitle={invalidURL ? "" : virtualEvent?.name}>
        <Head>
          <title>{tenant.virtual_festival_display_name}</title>
        </Head>
        <NoContentPlaceholder
          text={`Unable to find selected content, please double-check the URL`}
          emoji="🎞️"
        />
      </Layout>
    );
  }

  if (isAarp && virtualEvent?.end_time && (moment(virtualEvent?.end_time).add(1, "hour") <= moment())) {
    return (
      <Layout subtitle={virtualEvent?.name}>
        <div
          className="flex flex-col justify-center items-center"
          style={{ minHeight: "50vh" }}
        >
          <div className="text-center text-xl text-gray-300">
             Sorry, this screening has expired.<br/>Check out the AARP Virtual Community Center for upcoming movies and theater productions.
          </div>
          <div style={{ paddingTop: 25 }}>
            <Button
            onClick={() => {
              router.push("https://local.aarp.org/virtual-community-center/movies-music-more/?cmp=LNK-CON-VCC-Events-44201-Eventive-202305-standard-Multi-MoviesMusicMore");
            }}>
              See more events
            </Button>
          </div>
        </div>
      </Layout>
    );
  }

  const selectedCurrency = router.query.currency || defaultCurrency;
  if (hasMultiCurrency && selectedCurrency) {
    salesOptions = salesOptions.filter(o => o.currency === selectedCurrency);
  }

  // Determine gift option
  let giftableSalesOption =
    virtualEvent.standalone_ticket_sales_enabled &&
    virtualEvent.visibility !== "hidden" &&
    !virtualEvent.disable_give_as_a_gift &&
    !virtualEvent.external_tickets_url &&
    salesOptions.find(o => !o.pass);
  if (giftableSalesOption) {
    giftableSalesOption = { ...giftableSalesOption, gift: true };
  }

  // If there is at least one applicable pass, don't show any non-$0 non-pass options,
  // or non-applicable pass options, to reduce confusion.
  if (salesOptions.some(o => o.pass && !o.error_message)) {
    salesOptions = salesOptions.filter(
      o => (!o.price && !o.error_message) || (o.pass && !o.error_message)
    );
  }

  // Filter errors from a pass only being applicable to a certain ticket bucket.
  // Find all passes that are successfuly applied to at least one option, then
  // filter out the appearance of them in other options.
  const goodPasses = salesOptions
    .filter(o => o.pass && !o.error_message)
    .map(o => o.pass);
  salesOptions = salesOptions.filter(
    o => !o.pass || !o.error_message || goodPasses.indexOf(o.pass) === -1
  );

  let minimumPrice = Number.POSITIVE_INFINITY;
  for (const o of salesOptions) {
    if (
      !o.error_message &&
      (hasMultiCurrency || !o.variantId) &&
      o.price < minimumPrice
    ) {
      minimumPrice = o.price;
    }
  }
  let isVariablePrice = virtualEvent.ticket_buckets.some(
    b => b.public && b.variable_price
  );

  const isInAvailabilityWindow =
    !virtualEvent.start_time ||
    (Date.now() >= new Date(virtualEvent.start_time) &&
      Date.now() < new Date(virtualEvent.end_time));
  const isPastAvailabilityWindow =
    virtualEvent.end_time &&
    Date.now() > new Date(virtualEvent.start_time) &&
    Date.now() > new Date(virtualEvent.end_time);
  const isInFuture =
    virtualEvent.start_time &&
    !isInAvailabilityWindow &&
    Date.now() < new Date(virtualEvent.start_time);
  const isAbleToEnterBroadcastEarly =
    !isPastAvailabilityWindow &&
    virtualEvent.start_time_enter_broadcast &&
    Date.now() >= new Date(virtualEvent.start_time_enter_broadcast);
  const isPreorderable = virtualEvent.allow_preorder && isInFuture;
  const hasFreeOption = salesOptions.some(o => o.price <= 0);
  const hasPassOptions = salesOptions.some(o => o.pass);
  const showTicketBucketNames = salesOptions.some((o, i) => {
    const otherIndex = salesOptions.findIndex(
      c => c.ticket_bucket_id !== o.ticket_bucket_id && c.pass === o.pass
    );
    return o.variantId || (otherIndex !== -1 && otherIndex !== i);
  });
  const unlockInterval =
    isInAvailabilityWindow &&
    Date.now() + virtualEvent.unlock_interval > new Date(virtualEvent.end_time)
      ? new Date(virtualEvent.end_time) - Date.now()
      : virtualEvent.unlock_interval;

  const numberOfFilms = virtualEvent.films.filter(f => f.type !== "livestream")
    .length;
  const numberOfLivestreams = virtualEvent.films.filter(
    f => f.type === "livestream"
  ).length;
  const featuredLivestream =
    numberOfLivestreams &&
    virtualEvent.films.find(f => f.type === "livestream");

  if (!isInAvailabilityWindow && !isPreorderable) {
    giftableSalesOption = null;
  }

  const trailerUrl = selectedContent.trailer_url || virtualEvent.trailer_url;

  const isExtremeSecurityBlocked =
    virtualEvent.extreme_security && !isExtremeSecurityOkay;
  const isExtremeSecurityLimited =
    virtualEvent.extreme_security_limited && !isExtremeSecurityOkay;

  const submitBallotVote = ({ data }) => {
    return api
      .post(`/ballots/person_vote`, {
        ...data,
        event_id: programId,
        token
      })
      .then(() => mutate(getEventEndpoint))
      .catch(e =>
        window.alert(`Failed to submit vote: ${e.message || "Unknown error"}`)
      );
  };

  const openCheckout = salesOption => {
    if (isPreorderable) {
      salesOption.preorder = true;
    }
    setCheckoutOptions({ salesOption });
    setShowCheckoutModal(true);
  };

  const watchNow = ({
    force,
    multifactor_code,
    multifactor_channel,
    contentId: watchContentId
  } = {}) => {
    if (
      !adminPreviewSettings &&
      !watchContentId &&
      !virtualEvent.person.available_ticket?.expires_at &&
      selectedContent.type !== "livestream" &&
      !virtualEvent.realtime_broadcast &&
      !force
    ) {
      setShowConfirmStartWatching(true);
      return;
    }

    if (!watchContentId) {
      watchContentId = selectedContentId;
    }
    let watchContent = virtualEvent.films.find(f => f.id === watchContentId);

    setIsWatchingNow(true);
    setWatchError(null);
    api
      .post(`watch/play`, {
        event_id: programId,
        film_id: watchContentId,
        multifactor_code,
        multifactor_channel,
        admin_preview: adminPreviewSettings
          ? adminPreviewSettings.token
          : undefined,
        token: adminPreviewSettings ? undefined : token,
        force_region: router.query.__region__ || undefined
      })
      .then(({ data }) => {
        setIsWatchingNow(false);

        if (data.multifactor_required) {
          setShowMultifactorInterstitial(data);
          return;
        } else {
          setShowMultifactorInterstitial(null);
        }

        if (watchContent.type === "livestream" || data.type === "livestream") {
          if (watchContent.type !== "livestream") {
            // Real-time broadcast - where content previously 'appeared' to be
            // a VOD film - always auto-advance to livestream if one exists
            const liveFilm = virtualEvent.films.find(
              f => f.type === "livestream"
            );
            if (liveFilm) {
              data.autoAdvanceToContentAndWatch = liveFilm.id;
            }
          }

          if (data.playlist) {
            data = data.playlist[data.selected_playlist_index];
            data.url = data.url_dash;
          }

          if (!data.url && !data.livestream_embed_code && !data.playlist) {
            setShowLivestreamWithOptions({
              ...data,
              repoll: () => watchNow({ contentId: watchContentId })
            });
          } else {
            setShowLivestreamWithOptions(data);
          }
        } else if (data) {
          if (
            navigator.userAgent.indexOf("GoNativeAndroid") !== -1 &&
            data.playlist &&
            data.playlist.some(p => p.virtual_content)
          ) {
            setShowExternalAppDestination(
              `${window.location.protocol}//${
                window.location.host
              }/app_proxy_play?data=${btoa(
                JSON.stringify(data)
              )}&from_android=${Date.now()}`
            );
            return;
          }

          if (virtualEvent.virtual_balloting_inline_during_playback) {
            data.includeBalloting = true;
          }

          const liveFilmIndex = virtualEvent.films.findIndex(
            f => f.type === "livestream"
          );
          if (liveFilmIndex !== -1) {
            const currentIndex = virtualEvent.films.findIndex(
              f => f.id === watchContentId
            );
            const liveFilm = virtualEvent.films[liveFilmIndex];
            if (
              liveFilm.start_time &&
              moment(liveFilm.start_time) <= moment().add(3, "hours") &&
              currentIndex < liveFilmIndex
            ) {
              data.autoAdvanceToContentAndWatch = liveFilm.id;
            }
          }

          data.playerMemoryKey = programId;

          setShowPlayerForPayload(data);

          // Fetch updates to balloting etc.
          localMutate();
        } else {
          setWatchError("Failed to load media. Please try again.");
        }
      })
      .catch(e => {
        setWatchError(e.message.substring(0, 240));
        setIsWatchingNow(false);
      });
  };

  let calendarEvent = null;
  if (selectedContent?.start_time || virtualEvent?.start_time) {
    calendarEvent = selectedContent?.start_time
      ? selectedContent
      : virtualEvent;
  }

  const detailContent = (
    <div>
      {virtualEvent?.virtual_balloting_enabled ? (
        <BallotingWidget
          className="mb-5"
          virtualEvent={virtualEvent}
          selectedContentId={selectedContentId}
          tenant={tenant}
          submitPersonVote={data => submitBallotVote({ data })}
        />
      ) : null}
      {virtualEvent?.geographic_restrictions_at_checkout ||
      virtualEvent?.geographic_restrictions?.length ||
      virtualEvent?.geographic_exclusions?.length ? (
        <div
          className={classNames(
            "text-sm text-gray-400 sm:flex items-center mb-5"
          )}
        >
          <span className="inline-flex mr-1 sm:mr-3 items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-200 text-green-800">
            Protected&nbsp;Content
          </span>
          {virtualEvent?.geographic_restrictions_at_checkout ? (
            <span className="flex-1">
              This content can only be viewed in authorized regions. Your
              location will be verified at the time of purchase.
            </span>
          ) : virtualEvent?.geographic_exclusions?.length ? (
            <span className="flex-1">
              This content can be viewed anywhere except for the following
              regions:{" "}
              <span className="font-semibold text-gray-300">
                {virtualEvent.geographic_exclusions.map(r => r.name).join(", ")}
              </span>
              .
            </span>
          ) : (
            <span className="flex-1">
              This content can only be viewed in authorized regions:{" "}
              <span className="font-semibold text-gray-300">
                {virtualEvent.geographic_restrictions
                  .map(r => r.name)
                  .join(", ")}
              </span>
              .
            </span>
          )}
        </div>
      ) : null}
      {SHOW_ANTIPIRACY_BADGE ? (
        <div
          className={classNames(
            "text-sm text-gray-400 sm:flex items-center mb-5"
          )}
        >
          <span className="inline-flex mr-1 sm:mr-3 items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-200 text-green-800">
            Advanced&nbsp;Antipiracy
          </span>
          <span className="flex-1">
            This screening is protected by an invisible forensic watermark that
            is unique to each viewer. Any unauthorized reproduction can be
            traced back to the original user.
          </span>
        </div>
      ) : null}
      {!isPastAvailabilityWindow && !isAppLockdownMode ? (
        <div className="mb-5 text-gray-300 text-sm flex items-center">
          {salesOptions.length &&
          !(tenant?.virtual_festival_hide_free_prices && minimumPrice === 0) ? (
            <span
              className="mr-4 inline-flex items-center justify-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gray-700 text-gray-100"
              style={{ flex: "0 0 50px", minHeight: 50 }}
            >
              {isVariablePrice ? (
                <span
                  className="p-1 text-sm text-center leading-4"
                  style={{ minWidth: 80 }}
                >
                  Pay&nbsp;What You&nbsp;Can
                </span>
              ) : (
                <span className="no-translate">
                  {formatPrice(minimumPrice, {
                    currency: selectedCurrency || tenant.currency,
                    short: true
                  })}
                </span>
              )}
            </span>
          ) : null}
          <span>
            <AvailabilityInformation
              event={virtualEvent}
              isInFuture={isInFuture}
              unlockInterval={unlockInterval}
              numberOfFilms={numberOfFilms}
              numberOfLivestreams={numberOfLivestreams}
              featuredLivestream={featuredLivestream}
              unlockInterval={unlockInterval}
              isAarp={isAarp}
              language={language}
            />{" "}
            <Link href="/help?back=1">
              <a className="underline hover:opacity-75 cursor-pointer whitespace-no-wrap">
                Need help?
              </a>
            </Link>
            {/* <a className="underline hover:opacity-75 cursor-pointer">
            Check your streaming compatibility.
          </a> */}
          </span>
        </div>
      ) : null}
      {virtualEvent?.description ? (
        <div
          className={classNames(
            "tenant-injected-html mb-3 text-gray-300",
            selectedContent?.description && "pb-3 border-b border-gray-600"
          )}
          dangerouslySetInnerHTML={{
            __html: virtualEvent.description
          }}
        />
      ) : null}
      <FilmDetailTable film={selectedContent} />
      <div className="mt-4">
        <SocialShare
          url={
            typeof window !== "undefined"
              ? `${window.location.protocol}//${window.location.host}${window.location.pathname}`
              : null
          }
          title={`${selectedContent?.name || virtualEvent.name} | ${
            tenant.virtual_festival_display_name
          }`}
          calendarEvent={calendarEvent}
          noSocialMedia={virtualEvent.visibility === "hidden"}
        />
      </div>
    </div>
  );

  const loginInstruction = !token ? (
    <div className="mb-2">
      {isAarp ? (
        <div>
          Already registered?{" "}
          <button
            className="underline hover:text-always-gray-200 focus:outline-none"
            onClick={() => requestLoginModal()}
            style={{
              textShadow: "0 2px 5px rgba(0, 0, 0, 0.5)"
            }}
          >
            Log in to your account
          </button>{" "}
          for access
        </div>
      ) : (
        <div>
          Already unlocked?{" "}
          <button
            className="underline hover:text-always-gray-200 focus:outline-none"
            onClick={() => requestLoginModal()}
            style={{
              textShadow: "0 2px 5px rgba(0, 0, 0, 0.5)"
            }}
          >
            Log in to your account
          </button>{" "}
          for access
        </div>
      )}
    </div>
  ) : null;

  const title = `${selectedContent?.name || virtualEvent.name}${
    selectedContent && virtualEvent.name !== selectedContent?.name
      ? ` | ${virtualEvent.name}`
      : ""
  } | ${tenant.virtual_festival_display_name}`;

  return (
    <Layout key="mainprogram" subtitle={invalidURL ? "" : virtualEvent.name}>
      {!invalidURL ? (
        <Head>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta
            property="og:description"
            content={
              virtualEvent?.short_description ||
              selectedContent?.short_description
            }
          />
          <meta
            property="og:image"
            content={selectedContent?.image || virtualEvent?.image}
          />
        </Head>)
      : null}
      {showCheckoutModal ? (
        <CheckoutModal
          eventId={programId}
          event={virtualEvent}
          {...checkoutOptions}
          onBackgroundDismiss={() => setShowCheckoutModal(false)}
          onSuccess={data => {
            localMutate();
            setIsRefreshing(true);
            setShowCheckoutModal(false);

            if (singleOrganization?.use_aarp_registration_flow) {
              toast.success(
                "You’re all set! Your registration has been confirmed."
              );
            }
          }}
        />
      ) : null}
      {showPlayerForPayload ? (
        <PlayerModal
          payload={{
            ...showPlayerForPayload,
            tenant: festivalId,
            userId: user?.id,
            restrictMaxQuality: !isExtremeSecurityOkay
          }}
          playerBalloting={
            showPlayerForPayload?.includeBalloting
              ? {
                  virtualEvent,
                  tenant,
                  submitBallotVote
                }
              : null
          }
          onEnded={() => {
            if (showPlayerForPayload?.autoAdvanceToContentAndWatch) {
              const newContentId =
                showPlayerForPayload.autoAdvanceToContentAndWatch;
              setShowPlayerForPayload(false);
              router.push(
                `/[festivalId]/play/[...programId]`,
                `/${festivalId}/play/${programId}/${newContentId}`,
                { shallow: true }
              );
              watchNow({ contentId: newContentId });
            }
          }}
          allowBackgroundDimiss
          onBackgroundDismiss={() => setShowPlayerForPayload(false)}
        />
      ) : null}
      {showLivestreamWithOptions ? (
        <LivestreamModal
          film={selectedContent}
          event={virtualEvent}
          options={{
            ...showLivestreamWithOptions,
            tenant: festivalId,
            userId: user?.id
          }}
          onEnded={() => {
            if (showLivestreamWithOptions?.autoAdvanceToContentAndWatch) {
              const newContentId =
                showLivestreamWithOptions.autoAdvanceToContentAndWatch;
              setShowLivestreamWithOptions(null);
              router.push(
                `/[festivalId]/play/[...programId]`,
                `/${festivalId}/play/${programId}/${newContentId}`,
                { shallow: true }
              );
              watchNow({ contentId: newContentId });
            }
          }}
          allowBackgroundDimiss
          onBackgroundDismiss={() => setShowLivestreamWithOptions(null)}
        />
      ) : null}
      {showConfirmStartWatching ? (
        <ConfirmWatchNowModal
          virtualEvent={virtualEvent}
          onConfirm={() => {
            setShowConfirmStartWatching(false);
            watchNow({ force: true });
          }}
          allowBackgroundDimiss
          onBackgroundDismiss={() => setShowConfirmStartWatching(false)}
        />
      ) : null}
      {showMutifactorInterstitial ? (
        <MultifactorInterstitialModal
          data={showMutifactorInterstitial}
          onSubmit={({ multifactor_code, multifactor_channel } = {}) => {
            watchNow({ multifactor_code, multifactor_channel, force: true });
          }}
          // allowBackgroundDimiss
          // onBackgroundDismiss={() => setShowMultifactorInterstitial(false)}
        />
      ) : null}
      {showExternalAppDestination ? (
        <Modal
          allowBackgroundDimiss
          onBackgroundDismiss={() => setShowExternalAppDestination(null)}
        >
          <div>
            <div>
              Your secure screening is ready! Please copy and paste the one-time
              link below into your mobile browser in order to watch this film.
            </div>
            {showExternalAppDestination !==
            EXTERNAL_APP_LINK_CONFIRMATION_MESSAGE ? (
              <Button
                className="mt-4"
                onClick={() => {
                  copy(showExternalAppDestination, {
                    message: "Press #{key} to copy",
                    debug: true,
                    format: "text/plain",
                    onCopy: () =>
                      setShowExternalAppDestination(
                        EXTERNAL_APP_LINK_CONFIRMATION_MESSAGE
                      )
                  });
                }}
              >
                Copy to clipboard
              </Button>
            ) : null}
            {!isAndroidApp ? (
              <div className="mt-4 font-medium text-sm">
                {showExternalAppDestination}
              </div>
            ) : null}
          </div>
        </Modal>
      ) : null}
      <div className="sm:flex -mx-4">
        <div className="sm:w-2/3 px-4">
          {!virtualEvent.person?.available_ticket ||
          selectedContent.type === "none" ||
          selectedContent.type === "vod" ||
          selectedContent.type === "external" ||
          selectedContent.type === "livestream" ? (
            <>
              <div className="relative sm:aspect-auto-16-by-9">
                <div className="relative flex-1 flex flex-col min-h-55vw sm:block sm:absolute sm:inset-0 sm:min-h-0">
                  {selectedContent.image ? (
                    <img
                      className="absolute inset-0 h-full z-0 object-cover rounded-lg overflow-hidden"
                      src={selectedContent.image}
                    />
                  ) : null}
                  {isInFuture ||
                  isExtremeSecurityBlocked ||
                  (isUnlocked && isExtremeSecurityLimited) ? (
                    <div
                      className="z-20 sm:absolute rounded-t-lg overflow-hidden top-0 left-0 right-0 px-4 py-2 text-center"
                      style={{
                        backgroundColor: "rgba(75, 85, 99, 0.85)"
                      }}
                    >
                      {isExtremeSecurityBlocked ? (
                        <div
                          className={classNames(
                            "text-lg text-red-300 font-bold",
                            isInFuture && "mb-1"
                          )}
                        >
                          {tenant.display_short_name} has chosen to block{" "}
                          {blockedExtremeSecurityBrowser} for this screening due
                          to enhanced security restrictions. Please unlock this
                          screening using {recommendedExtremeSecurityBrowser}{" "}
                          instead. Live chat support is not able to modify this
                          restriction.
                        </div>
                      ) : isUnlocked && isExtremeSecurityLimited ? (
                        <div
                          className={classNames(
                            "text-sm text-red-300",
                            isInFuture && "mb-1"
                          )}
                        >
                          The rightsholder has chosen to limit{" "}
                          {blockedExtremeSecurityBrowser} to 576p resolution for
                          this screening due to enhanced security restrictions.{" "}
                          <strong>
                            For the highest resolution experience, please watch
                            using {recommendedExtremeSecurityBrowser} on this
                            device, or via our television apps.
                          </strong>{" "}
                          Live chat support is not able to modify this
                          restriction.
                        </div>
                      ) : null}
                      {isInFuture ? (
                        <>
                          <span className="text-lg text-always-white">
                            {virtualEvent.realtime_broadcast
                              ? "Live in"
                              : "Available in"}
                          </span>{" "}
                          <Countdown
                            className="font-bold text-lg text-always-white no-translate"
                            style={{
                              fontVariantNumeric: "tabular-nums"
                            }}
                            to={new Date(virtualEvent.start_time)}
                          />
                        </>
                      ) : null}
                    </div>
                  ) : null}
                  <div
                    className={classNames(
                      "z-10 px-2 py-4 sm:p-0 sm:absolute sm:inset-0 flex-1 flex flex-col items-center justify-center",
                      isInFuture ? "rounded-b-lg sm:rounded-t-lg" : "rounded-lg"
                    )}
                    style={{
                      backgroundColor: "rgba(0,0,0,0.5)"
                    }}
                  >
                    <div className="items-center flex flex-col sm:flex-row">
                      {adminPreviewSettings ? (
                        <Button
                          size="huge"
                          disabled={isWatchingNow}
                          onClick={() => watchNow({ force: true })}
                        >
                          {isWatchingNow ? (
                            `Loading admin preview...`
                          ) : (
                            <>
                              Watch now{" "}
                              <span className="bg-red-500 px-2 text-xs ml-3 rounded">
                                ADMIN PREVIEW
                              </span>
                            </>
                          )}
                        </Button>
                      ) : isExtremeSecurityBlocked ? (
                        <Button size="huge" disabled={true}>
                          Blocked due to organizer security restrictions
                        </Button>
                      ) : virtualEvent.sales_disabled_due_to_revenue_share ? (
                        <Button size="huge" disabled={true}>
                          Host has not yet completed invite
                        </Button>
                      ) : isApp &&
                        isAppLockdownMode &&
                        !allowInAppPurchases &&
                        !allowInAppFreeUnlocks &&
                        !isUnlocked ? (
                        <Button size="huge" disabled={true}>
                          Not available
                        </Button>
                      ) : isRefreshing ? (
                        <Button size="huge" disabled={true}>
                          Loading...
                        </Button>
                      ) : isPreorderable &&
                        virtualEvent.person?.available_ticket &&
                        !isAbleToEnterBroadcastEarly ? (
                        <Button size="huge" disabled={true}>
                          {isAarp ? "Registered" : "Pre-ordered successfully"}
                        </Button>
                      ) : isPreorderable &&
                        !salesOptions.length &&
                        !isAbleToEnterBroadcastEarly ? (
                        <Button size="huge" disabled={true}>
                          {virtualEvent?.custom_button_label ||
                            tenant.virtual_festival_preorder_sold_out_text ||
                            "Pre-orders sold out"}
                        </Button>
                      ) : (virtualEvent?.ticket_buckets?.length === 0 ||
                          virtualEvent.external_tickets_url) &&
                        !isUnlocked ? (
                        <Button
                          size="huge"
                          disabled={!virtualEvent.external_tickets_url}
                          onClick={() => {
                            window.open(
                              virtualEvent.external_tickets_url,
                              "_blank"
                            );
                          }}
                        >
                          {virtualEvent?.custom_button_label ||
                            (virtualEvent.external_tickets_url
                              ? "Unlock now"
                              : "No ticket types available")}
                        </Button>
                      ) : virtualEvent?.ticket_buckets?.every(
                          b => b.quantity_remaining === 0
                        ) && !isUnlocked ? (
                        <Button size="huge" disabled={true}>
                          Sold out
                        </Button>
                      ) : !isPreorderable &&
                        ((selectedContent.type === "none" &&
                          !virtualEvent?.realtime_broadcast) ||
                          ((!salesOptions.length || !isInAvailabilityWindow) &&
                            !isUnlocked)) ? (
                        <Button size="huge" disabled={true}>
                          {virtualEvent?.custom_button_label ||
                            "Not currently available"}
                        </Button>
                      ) : (
                        <DropdownButton
                          button={
                            <span className="inline-flex rounded-md shadow-sm">
                              <button
                                type="button"
                                disabled={isWatchingNow}
                                className={classNames(
                                  "eventive__button inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-always-white transition ease-in-out duration-150 focus:outline-none",
                                  isWatchingNow
                                    ? "bg-indigo-400 cursor-not-allowed select-none"
                                    : `bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700`
                                )}
                                onClick={
                                  isUnlocked ||
                                  (selectedContent.type === "livestream" &&
                                    isLivestreamUnlocked)
                                    ? e => {
                                        e.stopPropagation();
                                        watchNow();
                                      }
                                    : virtualEvent.external_tickets_url
                                    ? e => {
                                        e.stopPropagation();
                                        window.open(
                                          virtualEvent.external_tickets_url,
                                          "_blank"
                                        );
                                      }
                                    : salesOptions.length === 1 &&
                                      !salesOptions[0].error_message
                                    ? e => {
                                        e.stopPropagation();
                                        openCheckout(salesOptions[0]);
                                      }
                                    : undefined
                                }
                              >
                                {isPreorderable && !isAbleToEnterBroadcastEarly
                                  ? `${(() => {
                                      const DEFAULT_LABEL = "Pre-order now";
                                      const label =
                                        virtualEvent?.custom_button_label ||
                                        tenant.virtual_festival_preorder_text ||
                                        DEFAULT_LABEL;
                                      return isAarp
                                        ? "Register now"
                                        : `${label}${
                                            label === DEFAULT_LABEL &&
                                            hasFreeOption
                                              ? " for free"
                                              : ""
                                          }`;
                                    })()}`
                                  : isWatchingNow
                                  ? "Loading..."
                                  : isUnlocked ||
                                    (selectedContent.type === "livestream" &&
                                      isLivestreamUnlocked)
                                  ? selectedContent.type === "livestream"
                                    ? "Enter livestream"
                                    : virtualEvent.realtime_broadcast
                                    ? isAarp
                                      ? aarpEventType === "show"
                                        ? "Watch the show"
                                        : "Watch the movie"
                                      : "Enter live broadcast"
                                    : "Watch now"
                                  : virtualEvent?.custom_button_label ||
                                    ((!isApp && hasFreeOption) ||
                                    (isApp &&
                                      hasFreeOption &&
                                      allowInAppFreeUnlocks &&
                                      !allowInAppPurchases)
                                      ? isAarp
                                        ? "Register now"
                                        : "Unlock for free"
                                      : "Unlock now")}
                                <svg
                                  className="ml-3 -mr-1 h-5 w-5"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    d="M5 3V7M3 5H7M6 17V21M4 19H8M13 3L15.2857 9.85714L21 12L15.2857 14.1429L13 21L10.7143 14.1429L5 12L10.7143 9.85714L13 3Z"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </span>
                          }
                          dropdown={
                            <div className="z-10 origin-top-right absolute right-0 mt-2 rounded-md shadow-lg w-64 sm:w-72 md:w-80 lg:w-96 mb-4">
                              <div className="rounded-md bg-always-white shadow-xs">
                                <div className="py-1">
                                  {salesOptions.map((option, i) => {
                                    return (
                                      <div
                                        key={`${option.pass || "standalone"}-${
                                          option.ticket_bucket_id
                                        }-${option.variantId}`}
                                        className={classNames(
                                          "block px-4 py-2 text-sm leading-5",
                                          i !== salesOptions.length - 1 &&
                                            "border-always-gray-100 border-b",
                                          option.error_message
                                            ? "select-none text-always-gray-400 hover:bg-always-gray-50"
                                            : "text-always-gray-700 hover:bg-always-gray-100 hover:text-always-gray-900 focus:outline-none focus:bg-always-gray-100 focus:text-always-gray-900 cursor-pointer"
                                        )}
                                        onClick={
                                          !option.error_message
                                            ? () => openCheckout(option)
                                            : null
                                        }
                                      >
                                        {option.error_message
                                          ? `Unable to ${
                                              isPreorderable
                                                ? "pre-order"
                                                : "unlock"
                                            }${
                                              showTicketBucketNames
                                                ? ` ${option.ticket_bucket_name}`
                                                : ""
                                            } with "${option.pass_name}": ${
                                              option.error_message
                                            }`
                                          : option.pass
                                          ? `${
                                              isPreorderable
                                                ? "Pre-order"
                                                : "Unlock"
                                            } ${
                                              showTicketBucketNames
                                                ? option.ticket_bucket_name
                                                : ""
                                            } with "${
                                              option.pass_name
                                            }" (${formatPrice(option.price, {
                                              short: true,
                                              currency:
                                                option.currency ||
                                                tenant.currency
                                            })})...`
                                          : option.with_credits
                                          ? `${
                                              isPreorderable
                                                ? "Pre-order"
                                                : "Unlock"
                                            } ${
                                              showTicketBucketNames
                                                ? option.ticket_bucket_name
                                                : ""
                                            } using one credit (${formatPrice(
                                              0,
                                              {
                                                short: true,
                                                currency:
                                                  option.currency ||
                                                  tenant.currency
                                              }
                                            )})...`
                                          : `${
                                              isPreorderable
                                                ? "Pre-order"
                                                : "Unlock"
                                            } ${
                                              showTicketBucketNames
                                                ? option.ticket_bucket_name
                                                : ""
                                            } ${
                                              hasPassOptions
                                                ? "without using a pass"
                                                : ""
                                            } (${formatPrice(option.price, {
                                              short: true,
                                              currency:
                                                option.currency ||
                                                tenant.currency
                                            })})...`}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          }
                        />
                      )}
                      {trailerUrl ? (
                        <Button
                          className="mt-3 sm:mt-0 sm:ml-3"
                          size="huge"
                          onClick={() =>
                            setShowPlayerForPayload({
                              playlist: [{ url: trailerUrl }],
                              selected_playlist_index: 0,
                              isTrailer: true
                            })
                          }
                        >
                          Play trailer
                        </Button>
                      ) : null}
                    </div>
                    <div
                      className="text-always-white mt-3 text-sm px-4 max-w-lg text-center"
                      style={{
                        textShadow: "0 2px 5px rgba(0, 0, 0, 0.5)"
                      }}
                    >
                      {watchError ? (
                        <span>{watchError}</span>
                      ) : selectedContent.type === "livestream" ? (
                        <div className="text-center">
                          <div>
                            {selectedContent.livestream_mode === "embedded"
                              ? "Enter the livestream at any time"
                              : selectedContent.livestream_mode === "external"
                              ? "After unlocking, access instructions sent via email"
                              : "Enter the livestream and chat at any time"}
                          </div>
                          <div
                            className={classNames(
                              "mt-1 text-xs",
                              loginInstruction && "mb-2"
                            )}
                          >
                            {new Date(selectedContent.start_time) < Date.now()
                              ? "Stream began"
                              : "Stream begins"}{" "}
                            <span className="no-translate">
                              {moment(selectedContent.start_time).format("LLL")}{" "}
                              {formatBrowserTimezone(
                                selectedContent.start_time,
                                tenant
                              )}
                            </span>
                          </div>
                          {loginInstruction}
                        </div>
                      ) : isUnlocked && !isPreorderable ? (
                        virtualEvent.realtime_broadcast ? (
                          <span>
                            Broadcasting live from{" "}
                            <span className="no-translate">
                              {moment(virtualEvent.start_time).format("h:mm a")}{" "}
                            </span>
                            to{" "}
                            <span className="no-translate">
                              {moment(virtualEvent.end_time).format("h:mm a")}{" "}
                              {formatBrowserTimezone(virtualEvent.start_time)}
                            </span>
                          </span>
                        ) : virtualEvent.person.available_ticket.expires_at ? (
                          <span>
                            Your access to this title expires{" "}
                            <span className="no-translate">
                              {moment(
                                virtualEvent.person.available_ticket.expires_at
                              ).fromNow()}
                            </span>
                          </span>
                        ) : (
                          <span>
                            You have{" "}
                            <span className="no-translate">
                              {moment(
                                virtualEvent.person.available_ticket
                                  .unlocked_until
                              ).fromNow(true)}{" "}
                            </span>
                            to start watching this title
                          </span>
                        )
                      ) : !isInAvailabilityWindow ? (
                        <div className="text-center">
                          {isPastAvailabilityWindow ? (
                            <>
                              Expired{" "}
                              <span className="no-translate">
                                {moment(virtualEvent.end_time).format("LLL")}
                              </span>
                            </>
                          ) : (
                            <>
                              {isAarp ? "Screening " : "Available "}
                              <span className="no-translate">
                                {moment(virtualEvent.start_time).format("LLL")}{" "}
                                {formatBrowserTimezone(
                                  virtualEvent.start_time,
                                  tenant
                                )}
                              </span>
                            </>
                          )}
                          {loginInstruction}
                        </div>
                      ) : (
                        <div className="text-center">
                          {(token || salesOptions.length) &&
                          !isAppLockdownMode ? (
                            virtualEvent.films.length > 1 ? (
                              <div>
                                Unlock{" "}
                                <>
                                  {numberOfFilms} film
                                  {numberOfFilms > 1 ? "s" : ""}
                                  {numberOfLivestreams
                                    ? ` + livestream`
                                    : ""}{" "}
                                </>
                                to stream instantly
                              </div>
                            ) : (
                              <div>Unlock to stream instantly</div>
                            )
                          ) : null}
                          {loginInstruction}
                        </div>
                      )}
                    </div>
                    {giftableSalesOption ? (
                      isAarp || isApp ? null : (
                        <p
                          className="text-always-white mt-1 text-sm px-4"
                          style={{
                            textShadow: "0 2px 5px rgba(0, 0, 0, 0.5)"
                          }}
                        >
                          <span
                            className="flex group cursor-pointer"
                            onClick={() => {
                              openCheckout(giftableSalesOption);
                            }}
                          >
                            <svg
                              className="h-5 w-5 mr-2 group-hover:text-red-300 transition duration-200 ease-in-out"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              style={{
                                filter:
                                  "drop-shadow(0 2px 5px rgba(0, 0, 0, 0.5))"
                              }}
                            >
                              <path
                                d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                clipRule="evenodd"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                            Give as a gift
                            <svg
                              className="h-4 w-4 -ml-4 group-hover:ml-2 self-center transform scale-0 group-hover:scale-100 origin-left transition-all duration-200 ease-in-out"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                                fillRule="evenodd"
                              ></path>
                            </svg>
                          </span>
                        </p>
                      )
                    ) : null}
                    {virtualEvent.quantity_remaining &&
                    (isInAvailabilityWindow || isPreorderable) &&
                    !isUnlocked ? (
                      <p className="mt-3 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-indigo-100 text-indigo-800">
                        <svg
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          className="w-4 h-4 inline mr-1"
                        >
                          <path
                            fillRule="evenodd"
                            d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        Going fast! Only {virtualEvent.quantity_remaining}{" "}
                        unlock
                        {virtualEvent.quantity_remaining === 1 ? "" : "s"} left
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="hidden sm:block mt-6 border-gray-600 px-4">
                {detailContent}
              </div>
            </>
          ) : null}
        </div>
        <div className="sm:w-1/3 sm:mt-0 mt-12 px-4 sticky top-0">
          {hasMultiCurrency ? (
            <div className="mb-8 text-gray-200 rounded-md border border-gray-600 bg-gray-700 px-4 py-4">
              <div className="mb-4 text-sm font-medium text-gray-300 text-center">
                This screening is priced in multiple currrencies.
              </div>
              <div className="relative z-0 flex justify-center">
                {availableCurrencies.map((currency, i) => (
                  <button
                    key={currency}
                    type="button"
                    className={classNames(
                      "relative inline-flex items-center px-4 py-2 border border-always-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo transition ease-in-out duration-150 no-translate",
                      i === 0
                        ? "rounded-l-md"
                        : i === availableCurrencies.length - 1
                        ? "rounded-r-md -ml-px"
                        : "-ml-px",
                      selectedCurrency === currency
                        ? "shadow-outline-indigo border-indigo-300 bg-indigo-500 z-10 text-always-white"
                        : "bg-always-white text-always-gray-700"
                    )}
                    onClick={e => {
                      e.preventDefault();
                      router.replace({
                        pathname: router.pathname,
                        query: {
                          ...router.query,
                          currency
                        }
                      });
                    }}
                  >
                    {currency.toUpperCase()}
                  </button>
                ))}
              </div>
            </div>
          ) : null}
          <div className="-my-6 relative -mb-2 sm:-mb-6 pb-6">
            <div
              className="absolute slider-up-background h-6 z-10 pointer-events-none"
              style={{ top: 0, left: "-1rem", right: "1rem" }}
            />
            {supportsCustomScrollbars() && contentSidebarScrollHeight >= 400 ? (
              <div
                className="absolute h-6 w-4 z-10 bg-gray-800"
                style={{ top: 0, right: 0 }}
              />
            ) : null}
            <div
              className="absolute slider-down-background h-6 bottom-5 z-10 pointer-events-none"
              style={{ left: -10, right: 20 }}
            />
            <div className="absolute bottom-2 left-0 right-0 flex justify-center z-10">
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gray-300 text-gray-800">
                {numberOfFilms > 0 ? (
                  <>
                    {numberOfFilms} film
                    {numberOfFilms > 1 ? "s" : ""}
                    {numberOfLivestreams ? ` + livestream` : ""} in package
                  </>
                ) : numberOfLivestreams ? (
                  <>1 livestream in package</>
                ) : (
                  <>Nothing in package</>
                )}
              </span>
            </div>
            <div
              className={classNames(
                "relative pl-2 -ml-2 scrollbarred",
                contentSidebarScrollHeight < 400 ? "-mr-2 pr-2" : "pr-5"
              )}
              style={{ maxHeight: 400, overflowY: "auto" }}
              ref={contentSidebar}
            >
              {virtualEvent.films.map(
                ({
                  id,
                  image,
                  name,
                  short_description,
                  type,
                  start_time,
                  end_time,
                  captions_available,
                  livestream_mode
                }) => (
                  <Link
                    scroll={false}
                    href="/[festivalId]/play/[...programId]"
                    as={`/${router.query.festivalId}/play/${programId}/${id}`}
                    key={id}
                  >
                    <a
                      className={classNames(
                        "block shadow rounded-lg border-2 my-6 px-4 py-5 sm:p-6",
                        id === selectedContentId
                          ? "border-gray-600 bg-gray-700 shadow-outline-gray"
                          : "border-gray-700 bg-gray-800 cursor-pointer",
                        "hover:shadow-outline-gray hover:bg-gray-700 transition-all duration-150"
                      )}
                      ref={
                        id === selectedContentId
                          ? selectedContentSidebarItemRef
                          : undefined
                      }
                    >
                      <div className="text-gray-200 font-semibold no-translate">
                        {name}
                      </div>
                      <div
                        className={classNames(
                          "text-gray-300 text-sm no-translate",
                          id !== selectedContentId && "line-clamp-2-lines"
                        )}
                      >
                        {short_description}
                      </div>
                      {captions_available && (
                        <div className="text-gray-400 mt-2 text-sm flex flex-row items-center">
                          <svg
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6 mr-1"
                            style={{ marginLeft: -2 }}
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 7H9.5v-.5h-2v3h2V13H11v1c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v1zm7 0h-1.5v-.5h-2v3h2V13H18v1c0 .55-.45 1-1 1h-3c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v1z" />
                          </svg>
                          <span>Closed captions available</span>
                        </div>
                      )}
                      {type === "livestream" ? (
                        <LiveBadge
                          start_time={start_time}
                          end_time={end_time}
                          livestream_mode={livestream_mode}
                          className="mt-2"
                        />
                      ) : null}
                    </a>
                  </Link>
                )
              )}
            </div>
          </div>
          {virtualEvent.display_ads && !isApp
            ? virtualEvent.display_ads.map(({ url, image }, index) => (
                <div
                  key={index}
                  className={
                    (index === 0 ? "mt-12" : "mt-6") +
                    " rounded-lg overflow-hidden"
                  }
                >
                  {url ? (
                    <a href={url} target="_blank">
                      <img className="w-full" src={image} />
                    </a>
                  ) : (
                    <img className="w-full" src={image} />
                  )}
                </div>
              ))
            : null}
        </div>
        <div className="sm:hidden mt-6 border-gray-600 px-6">
          {detailContent}
        </div>
      </div>
    </Layout>
  );
}

export async function getServerSideProps({ locale, query, res, req }) {
  if (res) {
    res.setHeader("Cache-Control", "s-maxage=1, stale-while-revalidate");
  }

  const tenant = await fetchTenantConfig(query.festivalId);
  const [programId, contentId] = query.programId || [];
  const getEventEndpoint = `watch/events/${programId}`;

  // Single org mode where trying to view a different org - prevent Google
  // from indexing the wrong site.
  let redirectDestination;
  if (singleOrganization && singleOrganization.id !== tenant.owner) {
    redirectDestination = `https://watch.eventive.org/${query.festivalId}/play/${programId}`;
  }

  if (redirectDestination && res) {
    res.writeHead(307, {
      Location: redirectDestination
    });
    res.end();
    return { props: {} };
  }

  let finalProps;
  try {
    const data = await api
      .get(getEventEndpoint, {
        maxContentLength: 400000,
        auth: { username: tenant.api_key }
      })
      .then(r => r.data);
    finalProps = {
      initialData: data,
      initialKey: getEventEndpoint,
      initialTenant: tenant
    };
  } catch (e) {
    finalProps = {};
  }
  return {
    props: {
      ...finalProps
    }
  };
}
