import { useEffect, useState } from "react";

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const pad = i => (i < 10 ? `0${i}` : i);

export default function Countdown({ to, onReached, secondsMinimal, ...rest }) {
  const [now, setNow] = useState(Date.now());
  const [reached, setReached] = useState(false);
  const delta = Math.max(0, to - now);

  useEffect(() => {
    if (now) {
      const id = setTimeout(() => setNow(Date.now()), 1000);
      return () => clearTimeout(id);
    }
  }, [now]);

  useEffect(() => {
    if (delta <= 0 && !reached) {
      setReached(true);
      if (onReached) {
        onReached();
      }
    }
  }, [delta, reached]);

  const days = Math.floor(delta / DAY);
  const hours = Math.floor((delta % DAY) / HOUR);
  const minutes = Math.floor((delta % HOUR) / MINUTE);
  const seconds = Math.floor((delta % MINUTE) / SECOND);

  return (
    <span {...rest}>
      {secondsMinimal ? (
        <>{seconds}s</>
      ) : (
        <>
          {pad(days)}d {pad(hours)}h {pad(minutes)}m {pad(seconds)}s
        </>
      )}
    </span>
  );
}
