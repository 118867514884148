export const DETAILS = {
  year: "Year",
  runtime: "Runtime (min)",
  language: "Language",
  country: "Country",
  premiere: "Premiere",
  rating: "Rating",
  genre: "Genre",
  subtitle_language: "Subtitle Language",
  content_warning: "Content Warning",
  sponsor: "Sponsor",
  awards: "Awards",
  note: "Note",
  social_media: "Social Media"
};

export const CREDITS = {
  director: "Director",
  screenwriter: "Screenwriter",
  producer: "Producer",
  executive_producer: "Executive Producer",
  co_producer: "Co-Producer",
  filmmaker: "Filmmaker",
  cast: "Cast",
  cinematographer: "Cinematographer",
  editor: "Editor",
  animator: "Animator",
  production_design: "Production Design",
  composer: "Composer",
  sound_design: "Sound Design",
  music: "Music"
};

const makeDataTable = (data, source, expanded_credits) => {
  return (
    <ul>
      {Object.keys(source)
        .map(k => {
          if (k === "social_media") {
            if (!data["facebook"] && !data["twitter"] && !data["instagram"]) {
              return null;
            } else {
              return (
                <li key={k}>
                  <div className="text-gray-400 leading-5 mt-3 uppercase text-xs">
                    {source[k]}
                  </div>
                  {data["facebook"] ? (
                    <div
                      className="text-gray-300 no-translate"
                      style={{ float: "left" }}
                    >
                      <a
                        className="no-underline m-2 cursor-pointer"
                        href={data["facebook"]}
                        target="_blank"
                        rel="noopener"
                        aria-label="Facebook"
                        style={{
                          marginLeft: -3,
                          marginRight: 12,
                          marginTop: -3
                        }}
                      >
                        <div
                          aria-hidden="true"
                          style={{
                            display: "inline-block",
                            width: "1em",
                            height: "1em"
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentcolor"
                              d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  ) : null}
                  {data["twitter"] ? (
                    <div
                      className="text-gray-300 no-translate"
                      style={{ float: "left" }}
                    >
                      <a
                        className="no-underline m-2 cursor-pointer"
                        href={data["twitter"]}
                        target="_blank"
                        rel="noopener"
                        aria-label="Twitter"
                        style={{
                          marginLeft: -3,
                          marginRight: 15,
                          marginTop: -3
                        }}
                      >
                        <div
                          aria-hidden="true"
                          style={{
                            display: "inline-block",
                            width: "1em",
                            height: "1em"
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            color="currentColor"
                          >
                            <path
                              fill="currentColor"
                              d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  ) : null}
                  {data["instagram"] ? (
                    <div
                      className="text-gray-300 no-translate"
                      style={{ float: "left" }}
                    >
                      <a
                        className="no-underline m-2 cursor-pointer"
                        href={data["instagram"]}
                        target="_blank"
                        rel="noopener"
                        aria-label="Instagram"
                        style={{
                          marginLeft: -3,
                          marginRight: 12,
                          marginTop: -3
                        }}
                      >
                        <div
                          aria-hidden="true"
                          style={{
                            display: "inline-block",
                            width: "1em",
                            height: "1em"
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 551.034 551.034"
                          >
                            <path
                              fill="currentColor"
                              class="logo"
                              id="XMLID_17_"
                              d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722 c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156 C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156 c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722 c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"
                            />

                            <path
                              fill="currentColor"
                              id="XMLID_81_"
                              d="M275.517,133C196.933,133,133,196.933,133,275.516 s63.933,142.517,142.517,142.517S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6 c-48.095,0-87.083-38.988-87.083-87.083s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083 C362.6,323.611,323.611,362.6,275.517,362.6z"
                            />

                            <circle
                              fill="currentColor"
                              id="XMLID_83_"
                              cx="418.306"
                              cy="134.072"
                              r="34.149"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  ) : null}
                </li>
              );
            }
          } else if (!data[k]) {
            return null;
          }
          return (
            <li key={k}>
              <div className="text-gray-400 leading-5 mt-3 uppercase text-xs">
                {k === "runtime" ? "Runtime" : source[k]}
              </div>
              <div className="text-gray-300 leading-5 no-translate">
                {data[k]}{" "}
                {k === "runtime" && !isNaN(data[k]) ? "minutes" : null}
              </div>
            </li>
          );
        })
        .filter(x => !!x)
      }
      {expanded_credits && expanded_credits.length ? (
          <div>
          {expanded_credits.map(fc => {
            return (
              <li key={fc.id}>
                <div className="text-gray-400 leading-5 mt-3 uppercase text-xs">
                  {fc.label}
                </div>
                <div className="text-gray-300 leading-5 no-translate">
                  {fc.value}
                </div>
              </li>
            )
          })}
          </div>
        ) : null}
    </ul>
  );
};

export default function FilmDetailTable({ film }) {
  return (
    <div>
      {film.description ? (
        <div
          className="tenant-injected-html mb-2 text-gray-300"
          dangerouslySetInnerHTML={{ __html: film.description }}
        />
      ) : null}
      {film.details ? (
        <div className="flex -mx-4">
          <div className="w-1/2 px-4">
            {makeDataTable(film.details, DETAILS)}
          </div>
          <div className="w-1/2 px-4">
            {makeDataTable(film.credits, CREDITS, film.expanded_credits)}
          </div>
        </div>
      ) : null}
    </div>
  );
}
