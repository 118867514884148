import Modal from "./Modal";
import { formatInterval } from "../util";
import Button from "./Button";
import moment from "moment";

export default function ConfirmWatchNowModal({
  virtualEvent,
  onConfirm,
  ...rest
}) {
  return (
    <Modal id="confirm" narrow={true} {...rest}>
      <h2 className="text-lg font-medium text-center text-always-gray-800">
        Are you sure?
      </h2>
      <p className="mt-4 text-center text-always-gray-700">
        Once you click "Watch now" below, you'll have{" "}
        <span className="font-semibold whitespace-no-wrap">
          {formatInterval(virtualEvent.watch_interval)}
        </span>{" "}
        to finish the program before it expires.
      </p>
      <div className="mt-5 text-center">
        <Button withSparkles size="huge" onClick={onConfirm}>
          Watch now
        </Button>
      </div>
      <div className="mt-5 text-sm text-always-gray-500">
        Not ready yet? You have until{" "}
        {moment(virtualEvent.person.available_ticket.unlocked_until).format(
          "LLLL"
        )}{" "}
        to start watching.
      </div>
    </Modal>
  );
}
