import classNames from "classnames";
import { useState, useEffect } from "react";
import Transition from "./Transition";
import Link from "next/link";
import DropdownButton from "./DropdownButton";

export default function BallotingWidget({
  virtualEvent,
  tenant,
  selectedContentId,
  submitPersonVote,
  isInline,
  className
}) {
  const [pendingVote, setPendingVote] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const pickYourFavorite = !!virtualEvent.person?.balloting?.pick_your_favorite;
  const filmBallot = (virtualEvent.person?.balloting || {})[selectedContentId];

  useEffect(() => {
    setPendingVote(null);
  }, [selectedContentId]);

  const showVoteEntry =
    !virtualEvent.virtual_balloting_closed &&
    !virtualEvent.virtual_balloting_consolidated &&
    (pickYourFavorite ||
      (filmBallot &&
        (isInline || !virtualEvent.virtual_balloting_inline_during_playback)));

  const submitVote = data => {
    setPendingVote(data.vote === null ? -1 : data.vote);
    return submitPersonVote(data)
      .then(() => {
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 1200);
      })
      .catch(() => setPendingVote(null));
  };

  if (isInline && !showVoteEntry) {
    return null;
  }

  const currentFilmName = virtualEvent.films.find(
    f => f.id === selectedContentId
  )?.name;

  return (
    <div
      className={classNames(
        !isInline && "-mx-2 sm:-mx-4 mb-4",
        "border border-gray-600 bg-gray-700 rounded p-4 text-gray-300 flex items-center relative",
        className
      )}
    >
      <svg fill="currentColor" viewBox="0 0 20 20" className="h-10 w-10">
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>{" "}
      </svg>
      {showVoteEntry ? (
        <div className="flex flex-col sm:flex-row flex-1 ml-4 items-center">
          <div className="flex-1">
            {isInline ? (
              currentFilmName
            ) : pickYourFavorite ? (
              <span>
                {tenant.virtual_festival_custom_balloting_pick_your_favorite_vote_instruction ||
                  "This screening is balloted. Cast your vote for your favorite film in the program."}
              </span>
            ) : (
              <span>
                <span className="font-medium">{currentFilmName}</span>:{" "}
                {tenant.virtual_festival_custom_balloting_vote_instruction ||
                  `Cast your vote on this film for our audience awards.`}
              </span>
            )}
          </div>
          <div
            className={classNames(
              "relative mt-2 sm:mt-0 sm:ml-6 self-stretch sm:self-auto flex-1",
              !pickYourFavorite && "flex justify-center"
            )}
          >
            {(() => {
              if (pickYourFavorite) {
                const favorite =
                  virtualEvent.person.balloting.current_favorite &&
                  virtualEvent.person.balloting.options.find(
                    f => f.id === virtualEvent.person.balloting.current_favorite
                  )?.name;

                return (
                  <DropdownButton
                    className="w-full"
                    button={
                      <span className="block w-full">
                        <button
                          type="button"
                          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                        >
                          {favorite ? (
                            <span>
                              <span className="text-gray-400">
                                Your favorite:
                              </span>{" "}
                              {favorite}
                            </span>
                          ) : (
                            "Select your favorite film"
                          )}
                          <svg
                            class="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </span>
                    }
                    dropdown={
                      <div className="z-10 origin-top-right absolute right-0 mt-2 rounded-md shadow-lg w-64 sm:w-72 md:w-80 lg:w-96 mb-4">
                        <div className="rounded-md bg-always-white shadow-xs">
                          <div className="py-1">
                            {virtualEvent.person.balloting.options.map(
                              (option, i) => {
                                return (
                                  <div
                                    key={option.id}
                                    className={classNames(
                                      "block px-4 py-2 text-sm leading-5",
                                      i !==
                                        virtualEvent.person.balloting.options
                                          .length -
                                          1 &&
                                        "border-always-gray-100 border-b",
                                      "text-always-gray-700 hover:bg-always-gray-100 hover:text-always-gray-900 focus:outline-none focus:bg-always-gray-100 focus:text-always-gray-900 cursor-pointer"
                                    )}
                                    onClick={() =>
                                      submitVote({
                                        film_id: option.id,
                                        vote: 5
                                      })
                                    }
                                  >
                                    {option.name}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    }
                  />
                );
              }

              let isReversed = false,
                options = [...filmBallot.options];

              if (options.some(l => +l === 1)) {
                isReversed = true;
                options.reverse();
              }

              return options.map((label, i) => {
                const vote = isReversed ? options.length - i : i + 1;

                const selected =
                  (pendingVote !== null
                    ? String(pendingVote)
                    : String(filmBallot.vote)) === String(vote);

                return (
                  <button
                    key={vote}
                    type="button"
                    className={classNames(
                      "relative flex-1 inline-flex justify-center items-center px-4 py-2 border text-sm leading-5 font-medium",
                      i === 0
                        ? "rounded-l-md"
                        : i === filmBallot.options.length - 1
                        ? "rounded-r-md -ml-px"
                        : "-ml-px",
                      selected
                        ? "border-indigo-600 bg-indigo-700 text-always-white focus:outline-none"
                        : "border-always-gray-300 bg-always-white text-always-gray-700 hover:text-always-gray-500 focus:z-10 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-always-gray-100 active:text-always-gray-700"
                    )}
                    onClick={e => {
                      submitVote({
                        film_id: selectedContentId,
                        vote: selected ? null : vote
                      });
                    }}
                  >
                    {selected ? (
                      <svg
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        className="w-6 h-6 mr-1 -my-2"
                      >
                        <path
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    ) : null}
                    {label === "👍" ? (
                      <span>Like</span>
                    ) : label === "👎" ? (
                      <span>Dislike</span>
                    ) : (
                      label
                    )}
                  </button>
                );
              });
            })()}
          </div>
        </div>
      ) : (
        <div className="ml-4">
          <span className="font-medium">
            {tenant.virtual_festival_custom_balloting_intro ||
              "This virtual screening is eligible for audience awards!"}
          </span>{" "}
          {virtualEvent.virtual_balloting_consolidated ? (
            <>
              You must watch every film in the festival in order to vote.{" "}
              <Link href="/[festivalId]/vote" as={`/${tenant.tenant}/vote`}>
                <a className="underline text-gray-100 hover:text-gray-300 font-medium">
                  Track your progress and cast your vote here.
                </a>
              </Link>
            </>
          ) : virtualEvent.virtual_balloting_closed ? (
            tenant.virtual_festival_custom_balloting_closed ||
            "The voting period has closed. Stay tuned for the results!"
          ) : virtualEvent.person?.available_ticket &&
            virtualEvent.virtual_balloting_inline_during_playback ? (
            "Vote in the popup that appears below the film after you start watching."
          ) : virtualEvent.person?.balloting ? (
            tenant.virtual_festival_custom_balloting_film_not_eligible ||
            `You're eligible to vote, but this particular film isn't balloted. Try selecting another film in the program.`
          ) : virtualEvent.person?.available_ticket ? (
            tenant.virtual_festival_custom_balloting_unlocked_instruction ||
            `Watch it and then cast your vote here.`
          ) : (
            tenant.virtual_festival_custom_balloting_instruction ||
            `Unlock it to cast your vote.`
          )}
        </div>
      )}
      <Transition
        show={showSuccess}
        enter="transition ease-out duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="z-20 absolute inset-0 transition-opacity">
          <div className="absolute inset-0 bg-always-gray-500 opacity-75"></div>
        </div>
      </Transition>
      <Transition
        show={showSuccess}
        enter="transition ease-out duration-150 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-150 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="z-20 origin-center absolute inset-0 flex items-center justify-center">
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm leading-5 font-medium text-green-800">
                  Vote recorded successfully
                </p>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
